import React from 'react';
import { view } from '@risingstack/react-easy-state'

import annotationStore from '../stores/annotationStore'
import editorStore from '../stores/editorStore'
import Item from './Item'

export default view(function EditWorkspace({ pageInfo, annotations }) {

  function mouseDown(e) {
    if(editorStore.selected.size > 1) {
      editorStore.clearSelection()
      editorStore.setEditing(false)
    }

    if(editorStore.tool === "text") {
      const id = annotationStore.addAnnotation(pageInfo.pageNumber, {
        type: 'text',
        x: e.nativeEvent.offsetX-4,
        y: e.nativeEvent.offsetY-12,
        text: "",
        isNew: true,
      })

      editorStore.clearSelection()
      editorStore.selectId(id)
      editorStore.setEditing(true)
    }
  }

  let cursorStyle = ""
  if(editorStore.tool === "text") {
    cursorStyle = "cursor-text"
  }

  return <div
            className={`absolute left-0 top-0 w-full h-full ${cursorStyle}`}
            onMouseDown={mouseDown}>
    {annotations && annotations.map(a => {
        return <Item key={a.id} annotation={a} />
    })}
  </div>
})