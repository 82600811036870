import { useState, useEffect, } from 'react'

export default function useMouseMove(ref, onMove, mouseDown, mouseUp) {
  const [ isDown, setIsDown ] = useState(false)
  const [ offset, setOffset ] = useState({x: 0, y: 0})

  useEffect(() => {
    if(!ref.current)
      return

    const el = ref.current

    const handleMd = e => {
      e.stopPropagation()
      setIsDown(true)
      setOffset({
        x: e.offsetX,
        y: e.offsetY,
      })

      if(mouseDown) {
        mouseDown(e)
      }
    }

    const handleMu = e => {
      e.stopPropagation()
      setIsDown(false)

      if(mouseUp) {
        mouseUp(e)
      }
    }

    el.addEventListener("mousedown", handleMd)
    document.addEventListener("mouseup", handleMu)
    
    return () => {
      el.removeEventListener("mousedown", handleMd)
      document.removeEventListener("mouseup", handleMu)
    }
  }, [ref, onMove])

  useEffect(() => {
    const handleMv = e => {
      e.stopPropagation()
      onMove(
        e.pageX - offset.x,
        e.pageY - offset.y)
    }

    if(isDown)
    {
      document.addEventListener("mousemove", handleMv)
    }
    
    return () => {
      document.removeEventListener("mousemove", handleMv)
    }
  }, [ref, onMove, isDown, offset])
}