import React, { useState, useRef, useEffect } from 'react';
import { view, autoEffect } from '@risingstack/react-easy-state'
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib'
import 'intersection-observer'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

import './App.css';
import ToolButton from './components/ToolBar/ToolButton'
import ToolBarSpacer from './components/ToolBar/ToolBarSpacer'
import ToolBar from './components/ToolBar/ToolBar'
import usePdf from './components/hooks/usePdf'
import PdfPage from './components/PdfPage'
import { useKeyDown } from './components/hooks/useKeyHandler';

import annotationStore from './components/stores/annotationStore'
import editorStore from './components/stores/editorStore'

library.add(fas, far);

function App() {
  const docEl = useRef()
  const fileEl = useRef()
  const saveEl = useRef()

  const [pdfLoc, setPdfLoc] = useState({
    url: '/example.pdf'
  })
  const [ fileName, setFileName ] = useState("example.pdf")
  useEffect(() => {
    document.title = fileName
  }, [fileName])

  const { pdfDoc, pdfPages } = usePdf({
    url: pdfLoc.url,
    data: pdfLoc.data,
    scale: 1
  })

  useEffect(() => {
    if(!pdfDoc)
      return

    let saveData = localStorage.getItem(`pdf-${pdfDoc.fingerprint}`)
    saveData = JSON.parse(saveData)
    if(saveData) {
      annotationStore.index = saveData.index || {}
      annotationStore.pages = saveData.pages || {}
      annotationStore.pages = saveData.pages || {}
    }
  }, [pdfDoc])

  autoEffect(() => {
    if(pdfDoc) {
      localStorage.setItem(`pdf-${pdfDoc.fingerprint}`, JSON.stringify(annotationStore))
    } 
  }, [pdfDoc])

  function handleEscape() {
    //editorStore.setTool('select')
    editorStore.clearSelection()
    editorStore.setEditing(false)
  }
  useKeyDown({current: document}, "Escape", handleEscape, true)

  function handleDelete() {
    if(editorStore.editing || editorStore.selected.size === 0)
      return
    
    const ids = [...editorStore.selected]
    editorStore.clearSelection()
    editorStore.setEditing(false)

    ids.forEach(id => {
      annotationStore.removeAnnotation(id)
    })
  }
  useKeyDown({current: document}, "Delete", handleDelete, true)
  useKeyDown({current: document}, "Backspace", handleDelete, true)

  function toolHotkey(tool) {
    return () => {
      if(!editorStore.editing)
        editorStore.setTool(tool)
    }
  }

  //useKeyDown({current: document}, "s", toolHotkey('select'), true)
  useKeyDown({current: document}, "t", toolHotkey('text'), true)
  useKeyDown({current: document}, "d", toolHotkey('draw'), true)

  function openFile() {
    fileEl.current.click()
  }

  async function fileSelected(evt) {
    const reader = new FileReader();
    setFileName(evt.target.files[0].name)

    const data = await new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort()
        reject(new Error("Failed to get input file"))
      }
      reader.onload = () => {
        resolve(reader.result)
      }

      reader.readAsBinaryString(evt.target.files[0]);
    })

    setPdfLoc({ data })
  }

  async function saveFile() {
    const data = await pdfDoc.getData()
    const doc = await PDFDocument.load(data)

    const helveticaFont = await doc.embedFont(StandardFonts.Helvetica)

    const pages = doc.getPages()
    pages.forEach((page, idx) => {
      const { width, height } = page.getSize()
      const annotations = annotationStore.pages[idx+1]

      if(annotationStore.pageHidden[idx+1]) {
        return
      }
      
      if(!annotations)
        return

      annotations.forEach((id) => {
        const a = annotationStore.index[id]

        if(a.type === 'text') {
          page.drawText(a.text, {
            x: a.x,
            y: height-(a.y+18),
            size: 16,
            font: helveticaFont,
            color: rgb(0, 0, 0),
          })
        }
      })
    })

    let idx = 0;
    pages.forEach((_, pageNum) => {
      if(annotationStore.pageHidden[pageNum+1]) {
        doc.removePage(idx)
      }
      else {
        // only increment the page number if we didn't remove a page
        idx++;
      }
    })

    const outData = await doc.save()
    
    let blob = new Blob([outData], {type: "application/pdf"});
    let link = saveEl.current
    link.href = window.URL.createObjectURL(blob);
    let fileName = "test.pdf";
    link.download = fileName;
    link.click();
  }

  function setPagesHidden(hidden) {
    for(let i = 1; i <= pdfPages.length; i++) {
      annotationStore.setPageHidden(i, hidden)
    }
  }

  return (
    <div className="mx-auto h-screen max-h-screen" ref={docEl}>
      <div className="h-12 bg-white shadow fixed left-0 right-0 top-0 z-10">
        <ToolBar className="p-2">
          <ToolButton icon={["far", "folder-open"]} tooltip="Open" onClick={openFile} />
          <ToolButton icon={["fa", "download"]} tooltip="Save" onClick={saveFile} />
          <ToolBarSpacer />
          {/* <ToolButton icon="mouse-pointer" tooltip="Select" selected={editorStore.tool === "select"} onClick={() => editorStore.setTool("select")}/> */}
          <ToolButton icon="i-cursor" tooltip="Text" selected={editorStore.tool === "text"} onClick={() => editorStore.setTool("text")} />
          <ToolButton icon="times" tooltip="Delete" selected={editorStore.tool === "delete"} onClick={() => editorStore.setTool("delete")}/>
          {/* <ToolBarSpacer />
          <ToolButton icon="i-cursor" tooltip="Text" selected={editorStore.tool === "text"} onClick={() => editorStore.setTool("text")} /> */}
          {/* <ToolButton icon="pen" tooltip="Draw" selected={editorStore.tool === "draw"} onClick={() => editorStore.setTool("draw")}/> */}
          <ToolBarSpacer />
          <ToolButton icon="eye" tooltip="Show All Pages"  onClick={() => setPagesHidden(false)} />
          <ToolButton icon="eye-slash" tooltip="Hide All APges" onClick={() => setPagesHidden(true)} />
          <ToolBarSpacer />
          <ToolButton icon="trash" tooltip="Remove all modifications" onClick={annotationStore.clearAnnotations} />
        </ToolBar>
      </div>
      <div className="flex flex-col items-center relative">
          <div className="h-12" />
          {pdfDoc && pdfPages.map((page, idx) => {
            return <PdfPage page={page} annotations={annotationStore.getAnnotations(idx+1)} key={idx} />
          })}
      </div>
      <input ref={fileEl} onChange={fileSelected} type="file" accept="application/pdf" className="hidden" />
      <a ref={saveEl} className="hidden"/>
    </div>
  );
}

export default view(App);
