import React, { useRef, useEffect } from 'react';
import { view } from '@risingstack/react-easy-state'
import ContentEditable from 'react-contenteditable'

import annotationStore from '../stores/annotationStore'
import { useKeyDown } from '../hooks/useKeyHandler';
import editorStore from '../stores/editorStore';

export default view(function Text({ annotation, selected, editing }) {
  const ref = useRef()
  const { text, isNew, edited, id } = annotation
  let editingStyle = editing ? "" : "select-none"

  if(editing && !edited) {
    editingStyle += " text-grey-3"
  }

  function onChange(e) {
    annotationStore.updateAnnotation(annotation.id, {
      text: ref.current.innerText,
      edited: true
    })
  }

  useEffect(() => {
    if(!ref.current)
      return

    if(editing) {
      ref.current.focus()
    }
  }, [editing, ref])

  useEffect(() => {
    if(!editing && !edited) {
      annotationStore.removeAnnotation(id)
    }
  }, [editing, edited, id])

  useEffect(() => {
    if(!ref.current)
      return

    if(isNew) {
      //document.execCommand('selectAll',false,null)
      annotationStore.updateAnnotation(annotation.id, {
        isNew: false,
      })
    }
  }, [isNew, ref, annotation])

  useKeyDown(ref, 'Enter', (evt) => {
    if(!evt.shiftKey) {
      evt.stopPropagation()
      editorStore.setEditing(false)
    }
  }, false)

  return <div>
      {!edited && <div className="mx-1 absolute text-grey-3">...</div>}
      <ContentEditable html={text} innerRef={ref} disabled={!editing} onChange={onChange} className={`${editingStyle} mx-1 min-w-2 bg-transparent outline-none whitespace-pre-wrap`} />
    </div>
})