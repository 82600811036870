import { useEffect, } from 'react'

export default function useKeyHandler(ref, eventName, keyName, handler, stopPropagation) {
  useEffect(() => {
    if(!ref.current)
      return

    const el = ref.current

    const handleEvent = e => {
      if(e.key === keyName) {
        if(stopPropagation)
          e.stopPropagation()
        handler(e);
      }
    }

    el.addEventListener(eventName, handleEvent)
    
    return () => {
      el.removeEventListener(eventName, handleEvent)
    }
  }, [ref, eventName, keyName, handler, stopPropagation])
}

export function useKeyUp(ref, keyName, handler, stopPropagation) {
  return useKeyHandler(ref, "keyup", keyName, handler, stopPropagation)
}

export function useKeyDown(ref, keyName, handler, stopPropagation) {
  return useKeyHandler(ref, "keydown", keyName, handler, stopPropagation)
}

export function useKeyPress(ref, keyName, handler, stopPropagation) {
  return useKeyHandler(ref, "keypress", keyName, handler, stopPropagation)
}