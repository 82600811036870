import { useState, useEffect, useRef } from 'react'

import useOnScreen from './useOnScreen'

export default function usePdfPage(canvasRef, pageInfo) {
  const [rendered, setRendered] = useState(false)
  const renderTask = useRef()
  const onScreen = useOnScreen(canvasRef, '300px')

  useEffect(() => {
    setRendered(false)
  }, [pageInfo])

  useEffect(() => {
    (async () => {
      const renderPage = async () => {
        const {
          page,
          dpRatio,
          viewport,
        } = pageInfo

        const canvasEl = canvasRef.current
        if(!canvasEl)
          return

        const context = canvasEl.getContext('2d')
        if(!context)
          return

        canvasEl.style.width = `${viewport.width / dpRatio}px`;
        canvasEl.style.height = `${viewport.height / dpRatio}px`;
        canvasEl.height = viewport.height;
        canvasEl.width = viewport.width;

        // Interrupt current page rendering, if one is in progress
        if(renderTask.current) {
          renderTask.current.cancel()
          return
        }

        renderTask.current = page.render({
          canvasContext: context,
          viewport,
          enableWebGL: true,
        })

        //wait till rendering finishes
        try {
          await renderTask.current.promise
          renderTask.current = null
          setRendered(true)
        }
        catch(e) {
          renderTask.current = null

          // Trigger a re-render if the previous render was canceled
          if(e && e.name === 'RenderingCancelledException' ) {
            renderPage(page)
          }
        }
      }
  
      if(pageInfo && !rendered && onScreen) {
        renderPage()
      }
    })()
  }, [canvasRef, pageInfo, onScreen, rendered])
}