import React, { useState, useEffect, useRef } from 'react'

const WORKER_SRC = "/pdf.worker.js"
const pdfjs = window['pdfjs-dist/build/pdf'];

export default function usePdf({
  canvasRef,
  url,
  data,
  scale = 1,
  rotate = 0,
  withCredentials = false,
}) {
  const [pdfDoc, setPdfDoc] = useState()
  const [pdfPages, setPdfPages] = useState([])

  useEffect(() => {
    (async () => {
      pdfjs.GlobalWorkerOptions.workerSrc = WORKER_SRC;
    
      let options = {}
      if(url) {
        options.url = url
        options.withCredentials = withCredentials
      }
      else {
        options.data = data
      }

      const doc = await pdfjs.getDocument(options)

      setPdfDoc(doc)
    })()
  }, [url, data, withCredentials])

  useEffect(() => {
    (async () => {
      if(pdfDoc) {
        let pages = []
        for(let i = 0; i < pdfDoc.numPages; i++) {
          const page = await pdfDoc.getPage(i+1)

          const rotation = rotate === 0 ? page.rotate : page.rotate + rotate
          const dpRatio = window.devicePixelRatio
          const adjustedScale = scale * dpRatio
          const viewport = page.getViewport({ scale: adjustedScale, rotation })

          pages.push({
            page,
            pageNumber: i+1,
            rotation,
            dpRatio,
            adjustedScale,
            scale,
            viewport,
            width: viewport.width / dpRatio,
            height: viewport.height / dpRatio,
          })
        }
        
        setPdfPages(pages)
      }
    })()
  }, [canvasRef, pdfDoc, scale, rotate])

  return { pdfDoc, pdfPages }
}