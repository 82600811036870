import React, { useRef, useState } from 'react';
import { view } from '@risingstack/react-easy-state'

import usePdfPage from './hooks/usePdfPage'
import EditWorkspace from './EditWidgets/EditWorkspace';
import annotationStore from './stores/annotationStore';

export default view(function ToolBarSpacer({ page, annotations }) {
  const hidden = annotationStore.isPageHidden(page.pageNumber)
  const canvasEl = useRef()
  const pageEl = useRef()
  usePdfPage(canvasEl, page)

  function toggle() {
    annotationStore.setPageHidden(page.pageNumber, !hidden)
  }

  return page ? <div className="shadow-lg mb-10" ref={pageEl} >
      <div className={hidden ? "relative hidden" : "relative"} style={{width: `${page.width}px`, height: `${page.height}px` }}>
        <canvas ref={canvasEl}/>
        <EditWorkspace pageInfo={page} annotations={annotations} />
      </div>
      <div className="flex flex-row bg-blue-3 leading-relaxed text-white text-xs p-1 text-center" style={{width: `${page.width}px` }}>
        <span className="ml-4">Page: {page.pageNumber}</span>
        <span className="flex-grow"></span>
        {!hidden && <button onClick={toggle} className="mr-4 font-bold no-underline hover:underline">Hide Page</button>}
        {hidden && <button onClick={toggle} className="mr-4 font-bold no-underline hover:underline">Show Page</button>}
      </div>
    </div> : <div />
})