import React from 'react';

export default function ToolBar({ children, className }) {
  const [ firstButton, ...buttons] = children 

  return (
    <div className={`flex flex-row ${className}`}>
      <div>
        {firstButton}
      </div>
      {buttons.map((button, index) => {
        return <div className="ml-1" key={index}>
          {button}
        </div>
      })}
    </div>
  )
}