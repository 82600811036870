import { store } from '@risingstack/react-easy-state'
import { v4 as uuidv4 } from 'uuid'
import editorStore from './editorStore'

const annotationStore = store({
  index: {},
  pages: {},
  pageHidden: {},
  getAnnotations(pageNum) {
    if(annotationStore.pages[pageNum])
      return annotationStore.pages[pageNum].map(id => annotationStore.index[id])
    else
      return []
  },
  addAnnotation(pageNum, annotation) {
    const id = uuidv4()
    if(!annotationStore.pages[pageNum]) {
      annotationStore.pages[pageNum] = []
    }

    annotation = {
      ...annotation,
      id,
      pageNum
    }

    annotationStore.pages[pageNum].push(annotation.id)
    annotationStore.index[annotation.id] = annotation

    return id
  },
  removeAnnotation(id) {
    const a = annotationStore.index[id]
    if(!a)
      return
    
    editorStore.deSelectId(id)
    
    annotationStore.pages[a.pageNum] = annotationStore.pages[a.pageNum].filter(e => id !== e)
    delete annotationStore.index[id]
  },
  moveAnnotation(id, x, y) {
    const a = annotationStore.index[id]
    if(!a)
      return
    
    annotationStore.index[id].x = x
    annotationStore.index[id].y = y
  },
  updateAnnotation(id, annotation) {
    const a = annotationStore.index[id]
    if(!a)
      return
    
    annotationStore.index[id] = {
      ...a,
      ...annotation
    }
  },
  isPageHidden(pageNum) {
    return Boolean(annotationStore.pageHidden[pageNum])
  },
  setPageHidden(pageNum, hidden) {
    annotationStore.pageHidden[pageNum] = hidden
  },
  clearAnnotations() {
    annotationStore.index = {}
    annotationStore.pages = {}
    annotationStore.pageHidden = {}
  },
})

export default annotationStore