import React, { useRef } from 'react';
import { view } from '@risingstack/react-easy-state'

import annotationStore from '../stores/annotationStore'
import editorStore from '../stores/editorStore'
import Text from './Text'
import useMouseMove from '../hooks/useMouseMove';

function renderItem(annotation, selected, editing) {
  if(annotation.type === 'text') {
    return <Text annotation={annotation} selected={selected} editing={editing} />
  }

  return <div></div>
}

export default view(function Item({ annotation }) {
  const { x, y } = annotation
  const selected = editorStore.isSelected(annotation.id)
  const editing = editorStore.editing && selected

  let selectedStyle = selected ? "border cursor-move" : "hover:border border-dashed cursor-move"
  if(editing) {
    selectedStyle = ""
  }

  function mouseDown(e) {
    e.stopPropagation()
    if (editorStore.tool === "delete") {
      editorStore.deSelectId(annotation.id)
      editorStore.setEditing(false)
      annotationStore.removeAnnotation(annotation.id)
    } 
    else if(!selected) {
      if(!e.shiftKey) {
        editorStore.clearSelection()
      }
      editorStore.selectId(annotation.id)
      editorStore.setEditing(false)
    }
  }

  function onDoubleClick() {
    editorStore.clearSelection()
    editorStore.selectId(annotation.id)
    editorStore.setEditing(true)
  }

  const ref = useRef()
  function mouseMove(x, y) {
    if(editing)
      return

    const rect = ref.current.offsetParent.getBoundingClientRect()
    x -= rect.left + window.pageXOffset
    y -= rect.top + window.pageYOffset
    annotationStore.moveAnnotation(annotation.id, x, y)
  }
  useMouseMove(ref, mouseMove, mouseDown)

  return <div 
              className={`absolute ${selectedStyle} border-black`} 
              style={{left: `${x}px`, top: `${y}px`}}
              onDoubleClick={onDoubleClick}
              ref={ref}>
    {renderItem(annotation, selected, editing)}
  </div>
})