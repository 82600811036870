import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function ToolButton({ icon, tooltip, onClick, selected }) {
  const selectedCSS = selected ? "bg-grey-9" : ""

  return (
    <button className={`rounded ${selectedCSS} w-8 h-8 hover:bg-grey-9 active:bg-grey-7"`} onClick={onClick} title={tooltip} >
      <FontAwesomeIcon icon={icon} fixedWidth className="mx-auto my-auto"/>
    </button>
  )
}