import { store } from '@risingstack/react-easy-state'

const editorStore = store({
  tool: 'text',
  selected: new Set(),
  editing: false,
  setTool(tool) {
    editorStore.tool = tool
  },
  isSelected(id) {
    return editorStore.selected.has(id)
  },
  selectId(id) {
    editorStore.selected.add(id)
  },
  deSelectId(id) {
    editorStore.selected.delete(id)
  },
  clearSelection() {
    editorStore.selected.clear()
  },
  setSelected(ids) {
    editorStore.selected = new Set(ids)
  },
  setEditing(editing) {
    editorStore.editing = editing
  },
})

export default editorStore